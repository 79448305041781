<template>
  <div class="flex-1">
    <template v-if="viewtype == 'photo'">
      <ul class="photo-list">
        <li class="photo-item" v-for="(item, index) in dataList" :key="index">
          <el-image
            style="width: 100%"
            :src="item.url"
            :preview-src-list="[item.url]"
            title="点击查看大图"
          >
          </el-image>
        </li>
      </ul>
    </template>
    <template v-else>
      <ul class="attacList">
        <li v-for="(item, index) in dataList" :key="index">
          <span>{{ index + 1 }}. {{ item.name }}</span>
          <el-link
            type="primary"
            class="ml10 blue"
            @click="downloadFile(item.url, item.name)"
            >下载</el-link
          >
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "attachfile",
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    viewtype: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    previewImage(item) {
      // let list = this.dataList.map((item) => item.url);
      // ImagePreview(item.url, list);
      console.log(item);
    },
    async downloadFile(path, name) {
      const blob = await this.$axios.get(path, {
        responseType: "blob",
      });
      let blob_url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = blob_url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blob_url);
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.photo-list {
  display: flex;
  flex-wrap: wrap;
  .photo-item {
    margin: 5px;
    width: 100px;
    height: 100px;
    background-color: #eee;
    cursor: pointer;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}
.attacList {
  // padding: 0px 20px;
  // width: 95%;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    div:last-child {
      text-align: right;
    }
    div {
      display: inline-block;
      width: 49%;
    }
  }
}
</style>
<style lang="scss">
.rectangle {
  background-color: rgba(0, 154, 227, 0.1);
  border: 1px solid #009a83;
  position: absolute;
}
</style>